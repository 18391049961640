import React from 'react'
import styled from 'styled-components'

const h3HeadingThreeWithShade = props => {
  return (
    <H3HeadingThreeWithShade style={{ color: 'var(--clr-grey-3)' }}>
      {props.children}
    </H3HeadingThreeWithShade>
  )
}

const h3HeadingThreeWithTopBottomLine = props => {
  return (
    <H3HeadingThreeWithTopBottomLine style={{ color: 'var(--clr-grey-3)' }}>
      {props.children}
    </H3HeadingThreeWithTopBottomLine>
  )
}

const horizontalLine = props => {
  return (
    <HorizontalLine style={{ color: 'var(--clr-grey-3)' }}>
      {props.children}
    </HorizontalLine>
  )
}

const h4HeadingThreeWithTopBottomLine = props => {
  return (
    <H4HeadingThreeWithShade style={{ color: 'var(--clr-grey-3)' }}>
      {props.children}
    </H4HeadingThreeWithShade>
  )
}

const emphasis = props => {
  return <Emphasis>{props.children}</Emphasis>
}

const Emphasis = styled.span`
  color: var(--clr-red-light);
`

const H3HeadingThreeWithShade = styled.h3`
  position: relative;
  padding-bottom: 1rem;
  padding-top: 0.4rem;
  border: 0.1px solid var(--clr-grey-3);
  text-align: center;
  margin-bottom: 1.5rem;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    content: '';
    border-top: 0.1px solid var(--clr-grey-3);
    background-image: -webkit-repeating-linear-gradient(
      135deg,
      var(--clr-grey-3),
      var(--clr-grey-3) 1px,
      transparent 2px,
      transparent 5px
    );
    background-image: repeating-linear-gradient(
      -45deg,
      var(--clr-grey-3),
      var(--clr-grey-3) 1px,
      transparent 2px,
      transparent 5px
    );
    background-size: 7px 7px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
`

const H3HeadingThreeWithTopBottomLine = styled.h3`
  position: relative;
  padding: 1rem 0.5rem;

  &:before,
  &:after {
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    content: '';
    background-image: -webkit-gradient(
      linear,
      right top,
      left top,
      from(#30cfd0),
      to(#330867)
    );
    background-image: -webkit-linear-gradient(right, #30cfd0 0%, #330867 100%);
    background-image: linear-gradient(to left, #30cfd0 0%, #330867 100%);
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }
`

const H4HeadingThreeWithShade = styled.h4`
  position: relative;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
  border: 0.1px solid var(--clr-grey-3);
  text-align: center;
  margin-bottom: 1.5rem;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    content: '';
    border-top: 0.1px solid var(--clr-grey-3);
    background-image: -webkit-repeating-linear-gradient(
      135deg,
      var(--clr-grey-3),
      var(--clr-grey-3) 1px,
      transparent 2px,
      transparent 5px
    );
    background-image: repeating-linear-gradient(
      -45deg,
      var(--clr-grey-3),
      var(--clr-grey-3) 1px,
      transparent 2px,
      transparent 5px
    );
    background-size: 7px 7px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
`

const HorizontalLine = styled.hr`
  border: 1px solid var(--clr-grey-9);
  margin: auto;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;

  width: 50%;
`

const Br1rem = styled.div`
  line-height: normal;
`

export {
  h3HeadingThreeWithShade,
  h3HeadingThreeWithTopBottomLine,
  emphasis,
  h4HeadingThreeWithTopBottomLine,
  horizontalLine,
}
