import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import TeX from '@matejmazur/react-katex'

import {
  Headings,
  Blockquote,
  Code,
  PrismSetup,
  PostMarkup,
} from './src/components/Complete'

const components = {
  h2: Headings.myH2,
  h4: Headings.myH4,
  inlineCode: Code,
  blockquote: Blockquote,
  pre: PrismSetup,
  div: props => {
    if (props.className.includes('math-display')) {
      import('katex/dist/katex.min.css')
      return <TeX block math={props.children} />
    }
    return <div {...props} />
  },
  span: props => {
    if (props.className.includes('math-inline')) {
      import('katex/dist/katex.min.css')
      return <TeX math={props.children} />
    }
    return <span {...props} />
  },
  h3HeadingThreeWithShade: PostMarkup.h3HeadingThreeWithShade,
  h3HeadingThreeWithTopBottomLine: PostMarkup.h3HeadingThreeWithTopBottomLine,
  h4HeadingThreeWithTopBottomLine: PostMarkup.h4HeadingThreeWithTopBottomLine,
  emphasis: PostMarkup.emphasis,
  horizontalLine: PostMarkup.horizontalLine,
}
export const wrapMDX = ({ element }) => {
  return (
    <>
      <MDXProvider components={components}>{element}</MDXProvider>
    </>
  )
}
