import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { InteractiveExample } from "../../../../src/posts/4-post/Pen";
import * as React from 'react';
export default {
  Link,
  GatsbyImage,
  getImage,
  InteractiveExample,
  React
};