import React from 'react'
import styled from 'styled-components'

export const Bump = () => {
  return (
    <CodeWrapper>
      <iframe
        style={{ width: '100%' }}
        height="400"
        frameborder="no"
        src="https://observablehq.com/embed/@kentarotakahira/blog8?cells=viewof+view2"
      ></iframe>
    </CodeWrapper>
  )
}

const CodeWrapper = styled.div`
  margin-bottom: 1rem;
`
