import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import * as React from 'react';
export default {
  Link,
  GatsbyImage,
  getImage,
  styled,
  React
};