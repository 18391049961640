import React from 'react'
import styled from 'styled-components'

export const InteractiveExample = () => {
  return (
    <CodeWrapper>
      <iframe
        style={{ width: '100%' }}
        height="561"
        frameborder="no"
        src="https://observablehq.com/embed/@kentarotakahira/blog4?cells=viewof+view"
      ></iframe>
    </CodeWrapper>
  )
}

const CodeWrapper = styled.div`
  margin-bottom: 1rem;
`
