import React from 'react'
const Code = ({ children }) => {
  return (
    <code
      style={{
        background: 'rgb(250, 248, 245)',
        color: 'var(--clr-grey-5)',
        paddingRight: '0.2rem',
        paddingLeft: '0.2rem',
        borderRadius: 'var(--radius)',
        marginLeft: '0.2rem',
        marginRight: '0.2rem',
      }}
    >
      {children}
    </code>
  )
}

export default Code
